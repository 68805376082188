import { Input } from '@simplifiers/ui/Form/Input';
import { Caption } from '@simplifiers/ui/Typography';
import { When } from '@simplifiers/ui/When';
import { TitleTooltip } from '../TitleTooltip/TitleTooltip';
import styles from './CaptionField.module.css';

type CaptionFieldProps = {
  title: string;
  fieldName: string;
  fieldValue: string;
  tooltip?: string;
  multiline?: boolean;
};

// TODO: Replace the disabled input fields with the locked fields that are added in another branch

export const CaptionField = ({ title, fieldName, fieldValue, tooltip, multiline = false }: CaptionFieldProps) => {
  return (
    <div className={styles.fieldWrapper}>
      <When isTrue={!!tooltip} fallback={<Caption className={styles.caption}>{title}</Caption>}>
        <TitleTooltip className={styles.caption} title={title} tooltip={tooltip} />
      </When>
      <Input name={fieldName} value={fieldValue} multiline={multiline} disabled aria-label={title} />
    </div>
  );
};
