import { InlineContent } from '@simplifiers/ui/Layout/InlineContent';
import { Title } from '@simplifiers/ui/Typography';
import { ReportRow } from '../../../utils/types';
import { TooltipCellContent } from './TooltipCellContent';
import { TableCellProps } from './types';

const isRowWithTooltip = (row: ReportRow): boolean => {
  if (!row) return false;
  return 'tooltip' in row && row.tooltip !== null;
};

export function StringCellContent(props: TableCellProps) {
  const { row, column } = props;

  const hasRowTooltipDescription = row && isRowWithTooltip(row) && row.tooltip?.tooltip !== undefined;

  const isAccountLabel = row.type === 'account';
  const accountLabel = row?.cells.find((cell) => cell.id === 'label');

  const accountInfo = {
    number: `${String(accountLabel?.value).slice(0, 4)} `,
    name: String(accountLabel?.value).slice(4),
  };

  const isSumRow = row.type === 'sum';
  const isHeaderRow = row.type === 'section';
  const isMainHeaderRow = isHeaderRow && row.level === 1;

  if (isMainHeaderRow) {
    return <Title>{String(column.value)}</Title>;
  }

  if (isSumRow) {
    return (
      <InlineContent justifyContent="space-between" alignItems="center">
        <b>{column.value}</b>
        {hasRowTooltipDescription && <TooltipCellContent row={row} />}
      </InlineContent>
    );
  }

  if (isAccountLabel) {
    return (
      <InlineContent justifyContent="space-between" alignItems="center">
        <div>
          <b>{accountInfo.number}</b>
          {accountInfo.name}
        </div>
        {hasRowTooltipDescription && <TooltipCellContent row={row} />}
      </InlineContent>
    );
  }

  return (
    <InlineContent justifyContent="space-between" alignItems="center">
      {column.value}
      {hasRowTooltipDescription && <TooltipCellContent row={row} />}
    </InlineContent>
  );
}
