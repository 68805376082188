import { formatNumber } from '@simplifiers/formatters/numbers';
import {
  GridTable,
  GridTableBody,
  GridTableCell,
  GridTableHead,
  GridTableHeader,
  GridTableRow,
} from '@simplifiers/ui/Table/GridTable';
import { When } from '@simplifiers/ui/When';
import { ManagementReportPart, ReportColumn } from '../../../../utils/types';
import styles from './ManagementReport.module.css';
import { CaptionField } from './components/CaptionField';
import { TitleTooltip } from './components/TitleTooltip';

type MultiYearOverviewProps = {
  multiYearOverview: ManagementReportPart;
};

export const MultiYearOverview = ({ multiYearOverview }: MultiYearOverviewProps) => {
  const titleTooltip = 'Nyckeltal som visar översikt för utveckling av verksamhet, ställning och resultat.';
  const { label, comment, commentLabel, table } = multiYearOverview;

  const isNumericColumn = (column: ReportColumn) => column.dataType === 'number';

  return (
    <>
      <TitleTooltip large title={label} tooltip={titleTooltip} aria-label="Flerårsöversikt tooltip" />
      <GridTable>
        <GridTableHead>
          <GridTableRow>
            {table.columns.map((col: ReportColumn) => (
              <GridTableHeader key={col.id} align={isNumericColumn(col) ? 'right' : 'left'}>
                {col.value}
              </GridTableHeader>
            ))}
          </GridTableRow>
        </GridTableHead>
        <GridTableBody>
          {table.rows.map((row) => (
            <GridTableRow key={row.id}>
              {row.cells.map((col: ReportColumn) => (
                <GridTableCell key={col.id} align={isNumericColumn(col) ? 'right' : 'left'}>
                  <When isTrue={col.dataType === 'number'} fallback={col.value}>
                    {formatNumber(col.value)}
                  </When>
                </GridTableCell>
              ))}
            </GridTableRow>
          ))}
        </GridTableBody>
      </GridTable>
      <div className={styles.gridContainer}>
        <CaptionField title={commentLabel} fieldName="multiYearComment" fieldValue={comment} />
      </div>
    </>
  );
};
