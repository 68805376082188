export type OrganizationBasicInfo = {
  organizationNumber: string;
  name: string;
  businessDescription: string;
  registeredOffice: string;
  status: Status[];
  periods: Period[];
  representatives: Representative[];
};

export type Period = {
  from: string | null;
  to: string | null;
  requiresAuditLog: boolean;
};

export type Status = {
  code: string | null;
  text: string | null;
  date: string | null;
};

export type Representative = {
  firstName: string;
  lastName: string;
  personalNumber: string;
  otherIdentity: string | null;
  roles: RepresentativeRole[];
};

export type RepresentativeRole = {
  code: RepresentativeRoleCode;
  text: RepresentativeRoleText;
};

export type RepresentativeRoleText = (typeof REPRESENTATIVE_OPTIONS)[keyof typeof REPRESENTATIVE_OPTIONS];

export type RepresentativeRoleCode = keyof typeof REPRESENTATIVE_OPTIONS;

export enum REPRESENTATIVE_OPTIONS {
  DELG = 'Särskild delgivningsmottagare',
  EFT = 'Extern firmatecknare (dvs. firmatecknare som inte sitter i styrelsen)',
  EVD = 'Extern VD (dvs. VD som inte sitter i styrelsen)',
  EVVD = 'Extern Vice VD',
  LE = 'Styrelseledamot',
  OF = 'Ordförande',
  REV = 'Revisor',
  REVH = 'Huvudansvarig revisor',
  REVL = 'Lekmannarevisor',
  REVS = 'Revisorssuppleant',
  REVSL = 'Suppleant för lekmannarevisor',
  SU = 'Suppleant',
  VD = 'Verkställande direktör',
  VVD = 'Vice verkställande direktör',
}
