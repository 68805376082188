import { useEffect } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { NavLink } from '@simplifiers/ui/Button';
import { Title } from '@simplifiers/ui/Typography';
import { When } from '@simplifiers/ui/When';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  answerQuestions,
  getOrderedQuestionsIds,
  isQuestionAnswered,
  matchAccountingSuggestionAnswer,
} from '../../data';
import { useQuestionsQuery } from '../../hooks';
import { Answer, Question as QuestionType, QuestionsContext, QuestionsForm } from '../../types';
import styles from './Question.module.css';
import { getAllAccounts } from './accounts';
import { AccountingSuggestion } from './components/AccountingSuggestion';
import { QuestionDescription } from './components/QuestionDescription';
import { VoucherForm } from './components/VoucherForm';

const getQuestionLink = (id: string) => {
  return `../../questions/${id}`;
};

const getNewQuestions = (questions: Record<string, QuestionType>, answers: Answer[]) => {
  const updatedQuestions = {};
  answers.forEach((a) => {
    updatedQuestions[a.questionId] = {
      ...questions[a.questionId],
      answer: a.answer,
    };
  });
  return { ...questions, ...updatedQuestions };
};

export const Question = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { questionId } = useParams() as { questionId: string };

  const form = useQuestionsQuery() as QuestionsForm;
  const { orderedQuestionsIds } = useOutletContext<QuestionsContext>();

  const question = form?.questions[questionId];
  const hasAccountingSuggestion = matchAccountingSuggestionAnswer(question.answer, question?.accounts?.triggerAnswer);

  const answerMutation = useMutation({
    mutationFn: answerQuestions,
    onSuccess: (_, variables) => {
      // data will be returned from mutation function call (api call in our case)
      queryClient.setQueryData(['questionsForm'], (oldForm: QuestionsForm) => {
        return oldForm
          ? {
              ...oldForm,
              questions: getNewQuestions(oldForm.questions, variables),
            }
          : oldForm;
      });
    },
  });

  const currentQuestionIndex = orderedQuestionsIds.indexOf(questionId);
  const prevIndex = currentQuestionIndex - 1;
  const nextIndex = currentQuestionIndex + 1;
  const prevQuestionLink = prevIndex >= 0 ? getQuestionLink(orderedQuestionsIds[prevIndex]) : '';
  const nextQuestionLink =
    nextIndex < orderedQuestionsIds.length ? getQuestionLink(orderedQuestionsIds[nextIndex]) : '';

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        prevQuestionLink && navigate(prevQuestionLink);
      }

      if (event.key === 'ArrowRight') {
        nextQuestionLink && navigate(nextQuestionLink);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [navigate, nextQuestionLink, prevQuestionLink]);

  if (!question) {
    return <div>Not found</div>;
  }

  const handleAnswer = (answer: string) => {
    const answers = [{ questionId: question.id, answer }];

    if (question.dependentQuestionsIds.length) {
      const childQuestionsWithAnswersIds = question.dependentQuestionsIds.filter((id) =>
        isQuestionAnswered(form?.questions[id].answer)
      );
      childQuestionsWithAnswersIds.forEach((id) => {
        answers.push({ questionId: id, answer: '' });
      });
    }

    answerMutation.mutate(answers);

    let navigateTo = '';
    const hasAccountingSuggestion = matchAccountingSuggestionAnswer(answer, question?.accounts?.triggerAnswer);

    if (!hasAccountingSuggestion) {
      if (question.dependentQuestionsIds.length) {
        const newQuestions = getNewQuestions(form?.questions, answers);
        const newOrderedQuestionsIds = getOrderedQuestionsIds(newQuestions);
        const currentQuestionIndex = newOrderedQuestionsIds.indexOf(question.id);
        if (currentQuestionIndex >= 0 && currentQuestionIndex < newOrderedQuestionsIds.length - 1) {
          const nextQuestionId = newOrderedQuestionsIds[currentQuestionIndex + 1];
          navigateTo = getQuestionLink(nextQuestionId);
        }
      } else {
        navigateTo = nextQuestionLink;
      }
    }

    if (navigateTo) {
      navigate(navigateTo);
    }
  };

  const handleSnooze = () => {
    nextQuestionLink && navigate(nextQuestionLink);
  };

  return (
    <>
      <Title large className={styles.title}>
        {question.categoryName}
      </Title>
      <div className={styles.questionContainer}>
        <QuestionDescription question={question} onAnswer={handleAnswer} onSnooze={handleSnooze} />

        <When isTrue={hasAccountingSuggestion}>
          <AccountingSuggestion accountsInfo={question.accounts} />
          <VoucherForm
            creditAccounts={getAllAccounts(question.accounts?.creditRange)}
            debitAccounts={getAllAccounts(question.accounts?.debitRange)}
          />
        </When>
      </div>

      <nav className={styles.nav}>
        <When isTrue={prevIndex >= 0} fallback={<span></span>}>
          <NavLink to={`../../questions/${orderedQuestionsIds[prevIndex]}`} iconName="arrow-left">
            Föregående fråga
          </NavLink>
        </When>
        <When isTrue={nextIndex < orderedQuestionsIds.length} fallback={<span></span>}>
          <NavLink to={`../../questions/${orderedQuestionsIds[nextIndex]}`} iconName="arrow-right" iconPosition="end">
            Nästa fråga
          </NavLink>
        </When>
      </nav>
    </>
  );
};
